/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Font Sizes"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for font sizes, use the ", _jsx(_components.code, {
        children: "theme.fontSizes"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  export const theme = {\n    fontSizes: {\n-     'xs': '.75rem',\n-     'sm': '.875rem',\n+     'tiny': '.875rem',\n      'base': '1rem',\n      'lg': '1.125rem',\n      'xl': '1.25rem',\n      '2xl': '1.5rem',\n-     '3xl': '1.875rem',\n-     '4xl': '2.25rem',\n      '5xl': '3rem',\n      '6xl': '4rem',\n+     '7xl': '5rem',\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "fontSizes"
      }), " is already defined in default theme:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  fontSizes: {\n    xs: '0.75rem',\n    sm: '0.875rem',\n    base: '1rem',\n    lg: '1.125rem',\n    xl: '1.25rem',\n    '2xl': '1.5rem',\n    '3xl': '1.875rem',\n    '4xl': '2.25rem',\n    '5xl': '3rem',\n    '6xl': '3.75rem',\n    '7xl': '4.5rem',\n    '8xl': '6rem',\n    '9xl': '8rem',\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
